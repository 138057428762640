import {FieldMasks} from '../../components/Checkout/constants';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {
  createOrderAndCharge,
  getCheckout,
  removeCoupon,
  removeGiftCard,
  removeLineItems,
  updateCheckout,
} from '@wix/ambassador-ecom-v1-checkout/http';
import {subscribe, unsubscribe} from '@wix/ambassador-ecom-v1-subscribe-request/http';
import {
  ApiAddress,
  Checkout,
  CustomField,
  FullAddressContactDetails,
  MultiCurrencyPrice,
} from '@wix/ambassador-ecom-v1-checkout/types';
import {HttpError} from '@wix/http-client';
import {CheckoutModel} from '../models/Checkout.model';
import {CheckoutErrorCode} from '../utils/errors';
import {CheckoutErrorModel} from '../models/CheckoutError.model';
import {BIService} from './BIService';
import {NavigationService} from './NavigationService';
import {PlaceOrderUrlParams} from '../../types/app.types';
import {ambassadorWithHeaders} from '../utils/ambassador.utils';

export interface MinimumOrderErrorData {
  minimumOrderAmount: MultiCurrencyPrice;
  remaining: MultiCurrencyPrice;
}

export class CheckoutService {
  private readonly checkoutId?: string;
  private readonly flowAPI: ControllerFlowAPI;
  private readonly siteStore: SiteStore;
  private readonly navigationService: NavigationService;
  private readonly biService: BIService;
  public readonly currency?: string;
  private originalShippingOptionTitle: string = '';

  public checkout!: CheckoutModel;
  public ambassadorCheckout!: Checkout;
  public placeOrderError?: CheckoutErrorModel;
  public updateCheckoutError?: CheckoutErrorModel;
  public applyCouponError?: CheckoutErrorModel;
  public applyGiftCardError?: CheckoutErrorModel;

  constructor({
    flowAPI,
    siteStore,
    biService,
    navigationService,
    currency,
  }: {
    flowAPI: ControllerFlowAPI;
    siteStore: SiteStore;
    biService: BIService;
    navigationService: NavigationService;
    currency?: string;
  }) {
    this.flowAPI = flowAPI;
    this.biService = biService;
    this.siteStore = siteStore;
    this.currency = currency;
    this.navigationService = navigationService;
    this.checkoutId = this.navigationService.checkoutId;
  }

  public async init(): Promise<void> {
    await this.fetchCheckout();
    this.originalShippingOptionTitle = this.checkout.selectedShippingOption?.title ?? '';
  }

  public async fetchCheckout(): Promise<void> {
    if (this.checkoutId) {
      const {data} = await ambassadorWithHeaders(
        getCheckout({id: this.checkoutId}),
        this.siteStore,
        this.flowAPI,
        this.currency
      );
      this.setCheckout(data.checkout as Checkout);
    } else {
      console.error('No checkoutId in appSectionParams');
      throw new Error('no checkout id');
    }
  }

  public async createOrderAndCharge(
    paymentDetailsId: string | undefined,
    urlParams: PlaceOrderUrlParams
  ): Promise<{orderId?: string; paymentResponseToken?: string | null} | undefined> {
    try {
      const {data} = await ambassadorWithHeaders(
        createOrderAndCharge({
          id: this.checkout.id,
          paymentToken: paymentDetailsId ?? this.navigationService.cashierPaymentId,
          urlParams,
        }),
        this.siteStore,
        this.flowAPI,
        this.currency
      );
      return data;
    } catch (error) {
      return this.handlePlaceOrderError(error as HttpError);
    }
  }

  private handlePlaceOrderError(error: HttpError): {orderId: string} | undefined {
    const errorModel = CheckoutErrorModel.fromHttpError(error);
    if (errorModel.code === CheckoutErrorCode.CHECKOUT_ALREADY_PAID) {
      return {orderId: errorModel.data?.orderId ?? errorModel.data?.subscriptionId};
    }
    this.placeOrderError = errorModel;

    if (this.placeOrderError.code === CheckoutErrorCode.GENERAL_ERROR) {
      this.biService.checkoutErrorTrackingForDevelopers(
        JSON.stringify(error?.response?.data),
        JSON.stringify(this.placeOrderError.data)
      );
    }
  }

  public async applyCoupon(couponCode: string): Promise<void> {
    try {
      const {data} = await ambassadorWithHeaders(
        updateCheckout({
          checkout: {
            id: this.checkout.id,
          },
          couponCode,
        }),
        this.siteStore,
        this.flowAPI,
        this.currency
      );
      this.setCheckout(data.checkout as Checkout);
      this.biService.couponApplied(this.checkout);
    } catch (error) {
      this.applyCouponError = CheckoutErrorModel.fromHttpError(error as HttpError);
      this.biService.errorWhenApplyingACoupon(couponCode, this.applyCouponError, this.checkout);
    }
  }

  public async removeCoupon(): Promise<void> {
    if (this.checkout.appliedCoupon?.code) {
      this.biService.removeACoupon(this.checkout);
      const {data} = await ambassadorWithHeaders(
        removeCoupon({
          id: this.checkout.id,
        }),
        this.siteStore,
        this.flowAPI,
        this.currency
      );
      this.setCheckout(data.checkout as Checkout);
    }
    this.applyCouponError = undefined;
  }

  public async applyGiftCard(giftCardCode: string): Promise<void> {
    try {
      const {data} = await ambassadorWithHeaders(
        updateCheckout({
          checkout: {
            id: this.checkout.id,
          },
          giftCardCode,
        }),
        this.siteStore,
        this.flowAPI,
        this.currency
      );
      this.setCheckout(data.checkout as Checkout);
      this.biService.giftCardCheckoutCodeApplied(this.checkout);
    } catch (error) {
      this.applyGiftCardError = CheckoutErrorModel.fromHttpError(error as HttpError);
      this.biService.checkoutErrorWhenApplyingAGiftCard(this.applyGiftCardError, this.checkout);
    }
  }

  public async removeGiftCard(): Promise<void> {
    if (this.checkout.giftCard?.obfuscatedCode) {
      this.biService.giftCardCheckoutRemoveCode(this.checkout);
      const {data} = await ambassadorWithHeaders(
        removeGiftCard({
          id: this.checkout.id,
        }),
        this.siteStore,
        this.flowAPI,
        this.currency
      );
      this.setCheckout(data.checkout as Checkout);
    }
    this.applyGiftCardError = undefined;
  }

  public async subscribe(): Promise<void> {
    await ambassadorWithHeaders(
      subscribe({
        email: this.checkout.buyerInfo.email,
      }),
      this.siteStore,
      this.flowAPI,
      this.currency
    );
  }

  public async unsubscribe(): Promise<void> {
    await ambassadorWithHeaders(
      unsubscribe({
        email: this.checkout.buyerInfo.email,
      }),
      this.siteStore,
      this.flowAPI,
      this.currency
    );
  }

  public async setBillingDetails({
    contactDetails,
    address,
  }: {
    contactDetails: FullAddressContactDetails;
    address: ApiAddress;
  }): Promise<void> {
    return this.updateCheckout({billingInfo: {contactDetails, address}}, [
      FieldMasks.billingContact,
      FieldMasks.billingAddress,
    ]);
  }

  public async setShippingInfo({
    contactDetails,
    shouldUpdateBillingWithShippingContact,
    email,
    customField,
    shippingAddress,
  }: {
    contactDetails: FullAddressContactDetails;
    shouldUpdateBillingWithShippingContact: boolean;
    email?: string;
    customField?: CustomField;
    shippingAddress?: ApiAddress;
  }): Promise<void> {
    return this.updateCheckout(
      {
        buyerInfo: {email},
        shippingInfo: {
          shippingDestination: shippingAddress ? {contactDetails, address: shippingAddress} : {contactDetails},
        },
        ...(customField ? {customFields: [customField]} : {}),
        ...(shouldUpdateBillingWithShippingContact ? {billingInfo: {contactDetails}} : {}),
      },
      [
        FieldMasks.shippingContact,
        FieldMasks.buyerInfoEmail,
        ...(shippingAddress ? [FieldMasks.shippingAddress] : []),
        ...(customField ? [FieldMasks.customField] : []),
        ...(shouldUpdateBillingWithShippingContact ? [FieldMasks.billingContact] : []),
      ]
    );
  }

  public async setCustomField(customField: CustomField): Promise<void> {
    return this.updateCheckout({customFields: [customField]}, [FieldMasks.customField]);
  }

  public async setBillingAddress(billingAddress: ApiAddress): Promise<void> {
    return this.updateCheckout({billingInfo: {address: billingAddress}}, [FieldMasks.billingAddress]);
  }

  public async setSingleAddress(address: ApiAddress): Promise<void> {
    return this.updateCheckout({billingInfo: {address}, shippingInfo: {shippingDestination: {address}}}, [
      FieldMasks.billingAddress,
      FieldMasks.shippingAddress,
    ]);
  }

  public async setShippingOption(shippingOptionId: string): Promise<void> {
    return this.updateCheckout({shippingInfo: {selectedCarrierServiceOption: {code: shippingOptionId}}}, [
      FieldMasks.selectedCarrierServiceOption,
    ]);
  }

  private async updateCheckout(checkout: Partial<Omit<Checkout, 'id'>>, fieldMask: FieldMasks[]): Promise<void> {
    try {
      const {data} = await ambassadorWithHeaders(
        updateCheckout({
          checkout: {
            id: this.checkout.id,
            ...checkout,
          },
          fieldMask,
        }),
        this.siteStore,
        this.flowAPI,
        this.currency
      );
      this.setCheckout(data.checkout as Checkout);
    } catch (error) {
      this.updateCheckoutError = CheckoutErrorModel.fromHttpError(error as HttpError);
    }
  }

  public async removeLineItem(lineItemId: string): Promise<void> {
    const {data} = await ambassadorWithHeaders(
      removeLineItems({
        id: this.checkout.id,
        lineItemIds: [lineItemId],
      }),
      this.siteStore,
      this.flowAPI,
      this.currency
    );
    this.setCheckout(data.checkout as Checkout);
  }

  public clearPlaceOrderError(): void {
    this.placeOrderError = undefined;
  }

  public clearUpdateCheckoutError(): void {
    this.updateCheckoutError = undefined;
  }

  public get originalShippingTitle(): string {
    return this.originalShippingOptionTitle;
  }

  private setCheckout(checkout: Checkout) {
    this.checkout = new CheckoutModel(checkout);
    this.ambassadorCheckout = checkout;
  }
}
