import {Checkout, FullAddressContactDetails, ApiAddress} from '@wix/ambassador-ecom-v1-checkout/types';
import {CheckoutModel} from '../domain/models/Checkout.model';
import {CheckoutErrorModel} from '../domain/models/CheckoutError.model';
import {CheckoutSettingsModel} from '../domain/models/checkoutSettings/CheckoutSettings.model';
import {PolicyButtonLocation, PolicyType} from '../components/Checkout/PolicyButtonWithDialog/PolicyButtonWithDialog';
import {ITrackEventName, ITrackEventParams} from '@wix/native-components-infra';
import {SlotId} from '../domain/utils/slotId';
import {PaymentsWidgetConfiguration} from '@wix/cashier-payments-widget/dist/src/types/PaymentsWidgetConfiguration';
import {PaymentsWidgetProps} from '@wix/cashier-payments-widget/dist/src/types/PaymentsWidgetProps';
import {MemberAddressesInfoModel} from '../domain/models/MemberAddressesInfo.model';
import {AddressWithContactModel} from '../domain/models/AddressWithContact.model';

export type ControllerProps = {
  isLoading: boolean;
  failedToFetch?: boolean;
  checkoutStore: CheckoutStoreProps;
  deliveryMethodStore: DeliveryMethodStoreProps;
  checkoutSettingsStore: CheckoutSettingsStoreProps;
  navigationStore: NavigationStoreProps;
  slotsStore: SlotStoreProps;
  checkboxesStore: CheckboxesStoreProps;
  stepsManagerStore: StepsManagerStoreProps;
  paymentStore: PaymentStoreProps;
  formsStore: FormsStoreProps;
  memberStore: MemberStoreProps;
};

export interface CheckoutStoreProps {
  checkout: CheckoutModel;
  ambassadorCheckout: Checkout;
  placeOrderError?: CheckoutErrorModel;
  applyCouponError?: CheckoutErrorModel;
  applyGiftCardError?: CheckoutErrorModel;
  updateCheckoutError?: CheckoutErrorModel;

  isPlaceOrderButtonDisabled: boolean;
  shouldRequireZipCode: boolean;
  shouldShowSubdivisionSelector: boolean;
  isFastFlow: boolean;
  isPickupFlow: boolean;
  isShippingFlow: boolean;
  applyCoupon: (couponCode: string) => Promise<void>;
  removeCoupon: () => Promise<void>;
  applyGiftCard: (giftCardCode: string) => Promise<void>;
  removeGiftCard: () => Promise<void>;
  setCustomField: (customFieldValue: string) => Promise<void>;
  removeLineItem: (lineItemId: string) => Promise<void>;
  isMissingCustomField: boolean;
  clickPlaceOrderButton: (
    shouldSubscribe: boolean,
    detailsId?: string
  ) => Promise<{orderId?: string; paymentResponseToken?: string | null} | undefined>;
  submitCustomerDetails: ({
    contactDetails,
    email,
    customFieldValue,
    shippingAddress,
  }: {
    contactDetails: FullAddressContactDetails;
    email?: string;
    customFieldValue?: string;
    shippingAddress?: ApiAddress;
  }) => Promise<void>;
  submitDeliveryMethod: () => void;
  setPaymentAndBillingDetails: ({
    contactDetails,
    address,
    setBillingSameAsShipping,
    activePaymentId,
  }: {
    contactDetails: FullAddressContactDetails;
    address: ApiAddress;
    setBillingSameAsShipping: boolean;
    activePaymentId?: string;
  }) => Promise<void>;
  setZipCode: (zipCodeValue: string) => Promise<void>;
  onAddCouponSectionOpen: () => void;
  onAddGiftCardSectionOpen: () => void;
  onErrorDialogClosed: () => void;
  onErrorDialogOpened: () => void;
  onPolicyClicked: (linkLocation: PolicyButtonLocation, policyType: PolicyType) => void;
  setSubdivision: (subdivisionValue: string) => Promise<void>;
  shouldShowGiftCardSection: boolean;
  shouldShowCouponSection: boolean;
  onLogoutDialogToggled: (isOpen: boolean) => void;
}

export interface PaymentStoreProps {
  cashierWidgetProps: PaymentsWidgetProps;
  cashierConfiguration: PaymentsWidgetConfiguration;
}

export interface FormsStoreProps {
  areFormsLoaded: boolean;
}
export interface MemberStoreProps {
  isMember: boolean;
  fetchAddresses: () => Promise<void>;
  addressesInfo: MemberAddressesInfoModel;
  defaultAddress?: AddressWithContactModel;
  shouldShowMemberInfoBox: boolean;
  currentUserEmail: string;
}

export interface CheckboxesStoreProps {
  isPolicyCheckboxChecked: boolean;
  toggleIsPolicyCheckboxChecked: (checked: boolean) => void;
  isDigitalPolicyCheckboxChecked: boolean;
  toggleIsDigitalPolicyCheckboxChecked: (checked: boolean) => void;
  isSubscriptionCheckboxChecked: boolean;
  toggleIsSubscriptionCheckboxChecked: (checked: boolean) => void;
  areCheckoutCheckboxesValid: boolean;
  wasFormSubmitted: boolean;
  setWasFormSubmitted: (wasSubmitted: boolean) => void;
  shouldShowCheckboxesSection: boolean;
}

export interface StepsManagerStoreProps {
  sendStageExpandedBIEvent: (stepId: StepId) => void;
  updateStepOnStage: (stepIndex: number, stepId: StepId) => void;
  openStep: (stepIndex: number) => void;
  sendEditStepClickedBIEvent: (stepId: StepId, previousStepName?: StepId) => void;
  shouldDisplayExpressCheckout: boolean;
  activeStep: ActiveStep;
  stepsList: CheckoutStep[];
}

export interface DeliveryMethodStoreProps {
  isSelectedShippingOptionPickup: boolean;
  canShipToDestination: boolean;
  setShippingOption: (shippingOptionId: string) => Promise<void>;
  hasShippingOptions: boolean;
  isUpdatingDeliveryMethod: boolean;
  beforePlaceOrderClicked: () => void;
}

export interface NavigationStoreProps {
  clickOnBackToSite: () => void;
  clickOnContinueShopping: () => void;
  clickOnReturnToCart: () => Promise<void>;
  clickOnEditCart: () => void;
  navigateToThankYouPage: (orderId: string) => Promise<void> | void;
  continueShoppingUrl: string;
  editCartUrl: string;
  trackEvent: (eventName: ITrackEventName, eventProps: ITrackEventParams) => void;
  onLogin: () => Promise<void>;
  onLogout: () => Promise<void>;
}

export interface CheckoutSettingsStoreProps {
  checkoutSettings: CheckoutSettingsModel;
  showCouponSP: boolean;
}

export interface SlotStoreProps {
  setSlotsParams: (slotId: SlotId) => void;
  setStepId: (slotId: SlotId, stepId: StepId | null) => void;
}

export type PlaceOrderUrlParams = {
  errorUrl?: string;
  cancelUrl?: string;
  successUrl?: string;
  pendingUrl?: string;
};

export enum CashierMandatoryField {
  street = 'street',
  houseNumber = 'houseNumber',
  address = 'address',
  city = 'city',
  countryCode = 'countryCode',
  state = 'state',
  zipCode = 'zipCode',
  phone = 'phone',
}

export type CashierMandatoryFieldsOverrides = {
  [key in CashierMandatoryField]?: boolean;
};

export enum StepState {
  EMPTY = 'empty',
  OPEN = 'open',
  COLLAPSED = 'collapsed',
}

export type CheckoutStep = {
  id: StepId;
  state: StepState;
};

export type StepImplementationProps = {
  index?: number;
};

export enum StepId {
  customerDetails = 'contact-details',
  deliveryMethod = 'delivery-method',
  payment = 'payment-and-billing',
  placeOrder = 'place-order',
}

export type ActiveStep = {
  stepId: StepId;
  stepIndex: number;
};
